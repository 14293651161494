// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  explorer: '/explorer',
  marketplace: '/marketplace',
  auction: '/listauction?saleType=Auction',
  apolloB: '/apollo-b',
  saved: '/profile',
  create: '/create',
  collection: '/collections',
  activity: '/activity',
  features: '/features',
  rewards: '/rewards'
};

export const PATH_DOCS = 'https://docs.pasarprotocol.io';
