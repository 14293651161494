import { Box, Button, Menu, MenuItem, ListItemIcon, Popover, MenuList, Paper } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Diamond, Info, Token } from '@mui/icons-material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { HexagonButton } from './HexagonButton';
import HexagonButtonWithBorder from './HexagonButtonWithBorder';
// hook
import useSignin from '../../hooks/useSignin';


const LandingNavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { sessionId } = useSignin();

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleMenuClick = (path) => {
    navigate(path)
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', padding: { md: '10px 60px', lg: '10px 150px' }, display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <RouterLink to="/home">
        <Box
          component="img"
          alt=""
          src="/static/landingPage/logos/colorfulLogo.svg"
          sx={{ width: 175, height: 70, cursor: 'pointer' }}
        />
      </RouterLink>

      <Box sx={{ display: "flex" }}>
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-haspopup="true"
          sx={{ color: 'white' }}
        >
          {t("learnMore")}
        </Button>
        {open && (
          <Paper
            style={{
              position: 'absolute',
              zIndex: 1,
              marginTop: '50px',
              border: '1px solid #cccccc20',
              backgroundColor: '#051343',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <MenuList>
              <MenuItem onClick={() => {
                handleMenuClick('/home/learn-more')
              }}>
                <ListItemIcon>
                  <Diamond />
                </ListItemIcon>
                {t('ourDiamonds')}
              </MenuItem>
              <MenuItem onClick={() => {
                handleMenuClick('/home/company')
              }}>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                {t('whoWeAre')}
              </MenuItem>
              <MenuItem onClick={() => {
                handleMenuClick('/home/landingPageV2')
              }}>
                <ListItemIcon>
                  <BusinessCenterIcon />
                </ListItemIcon>
                {t('whatWeDo')}
              </MenuItem>
              <MenuItem onClick={() => {
                handleMenuClick('/home/token-details')
              }}>
                <ListItemIcon>
                  <TokenOutlinedIcon />
                </ListItemIcon>
                {t('tokenDetails')}
              </MenuItem>
            </MenuList>
          </Paper>
        )}
        {/* Other buttons */}
        <HexagonButton onClick={() => navigate('/home/get-started')} sx={{ ml: 3, color: 'black' }}>{t("getStartedUppercase")}</HexagonButton>
        <HexagonButtonWithBorder onClick={() => navigate('/marketplace')} sx={{ ml: 3 }} title="Marketplace" textSx={{ fontSize: '1.1rem' }} />
        {!sessionId &&
          <HexagonButtonWithBorder onClick={() => navigate('/home/login')} sx={{ ml: 3 }} src="/static/LandingPage/hexagonButtonBorderSmall.svg" title={t("signin")} textSx={{ fontSize: '1.1rem' }} />
        }
      </Box>
    </Box >
  );
};

export default LandingNavBar;
