import React, { useEffect } from 'react'
// material
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import { MHidden } from '../../components/@material-extend';
//
import LandingNavBar from '../../components/landing/LandingNavBar';
import MobileNavbar from './MobileNavbar';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));


export default function MainNavbar() {
  const isOffset = useOffSetTop(40);
  useEffect(() => {
    const styleElement = document.createElement('style');

    styleElement.textContent = `
      body {
        margin: 0 !important;
        font-family: 'Titillium Web', sans-serif !important;
      }

      p, h1, h2, h3, h4, h5, h6, button {
        font-family: 'Titillium Web', sans-serif !important;
        color: white;
      }
    `;

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(styleElement);
    return () => {
      head.removeChild(styleElement);
    };
  }, []);
  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: '#09143A',
            height: { md: APP_BAR_DESKTOP }
          })
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <MHidden width="mdDown">
            <LandingNavBar />
          </MHidden>
          {/* <CentralizedSignInDialog /> */}
          {/* </MHidden> */}
          <MHidden width="mdUp">
            <MobileNavbar />
          </MHidden>
        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}
