import React, { useEffect } from 'react';
import { FRONTEND_URL_PRODUCTION, PAYPAL_ENV } from '../../envHandler';

export function PaypalLoginButton() {
  useEffect(() => {
    const script = document.createElement('script');
    // script.src = 'https://www.paypal.com/sdk/js?client-id=ASMTiwZBppyxaA3W40XKvMTemJZ-3Z5KZn2By5LPUTH4Zb94Q9fjDNI9kb3hYUk2Pp3QQerFvzIYCI1Q&disable-funding=credit,card';
    script.src = 'https://www.paypalobjects.com/js/external/api.js';
    script.async = true;
    script.onload = () => {
      window.paypal.use(['login'], (login) => {
        login.render({
          "appid": "ASMTiwZBppyxaA3W40XKvMTemJZ-3Z5KZn2By5LPUTH4Zb94Q9fjDNI9kb3hYUk2Pp3QQerFvzIYCI1Q",
          "authend": PAYPAL_ENV,
          "scopes": "email",
          "responseType": "code id_Token",
          "locale": "en-us",
          "buttonType": "LWP",
          "buttonShape": "pill",
          "containerid": "paypalContainer",
          "buttonSize": "lg",
          "fullPage": "true",
          "returnurl": FRONTEND_URL_PRODUCTION
        });
      });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div id="paypalContainer" />
  );
}