export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const donationAddress = "0x48353677e2aDe5f164a3731d7b18e22Ac0AbaFea";

export const blankAddress = "0x0000000000000000000000000000000000000000";

const addressForProduction = {
  MAIN_CONTRACT: {
    ESC: {
      sticker: "0xF63f820F4a0bC6E966D61A4b20d24916713Ebb95",
      market: "0xaeA699E4dA22986eB6fa2d714F5AC737Fe93a998",
      register: "0x3d0AD66765C319c2A1c6330C1d815608543dcc19"
    },
    ETH: {
      sticker: "0x020c7303664bc88ae92cE3D380BF361E03B78B81",
      market: "0x940b857f2D5FA0cf9f0345B43C0e3308cD9E4A62",
      register: "0x24A7af00c8d03F2FeEb89045B2B93c1D7C3ffB08"
    },
    FSN: {
      sticker: blankAddress,
      market: "0xa18279eBDfA5747e79DBFc23fa999b4Eaf2A9780",
      register: "0x020c7303664bc88ae92cE3D380BF361E03B78B81"
    }
  },
  feedsContract: "0x020c7303664bc88ae92cE3D380BF361E03B78B81",
  // erc20Contract: "0xf5d461622Df01421c3b6082F962fD7711E94a579",
  v1marketContract: "0x02E8AD0687D583e2F6A7e5b82144025f30e26aA0",
  diaContract: "0x2C8010Ae4121212F836032973919E8AeC9AEaEE5",
  welaContract: "0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4",
  glideContract: "0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27",
  elkContract: "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C",
  ethUsdcContract: "0xA06be0F5950781cE28D965E5EFc6996e88a8C141",
  bunnyContract: "0x75740FC7058DA148752ef8a9AdFb73966DEb42a8",
  bnbBusdContract: "0x9f1d0Ed4E041C503BD487E5dc9FC935Ab57F9a57",
  elaOnEthContract: "0xe6fd75ff38Adca4B97FBCD938c86b98772431867",
  pasarERC20Contract: '',
  pasarVestingContract: '',
  pasarStakingContract: '',
  pasarMiningContract: '',
  blockchain: 'Elastos Smart Chain (ESC)',
}

export const contracts = {
  diamondAddress: '0x54635485B11896DcBFBcf0007aC3f3CcF9bF9354',
  pointAddress: '0x1F3b8ab22B3b1Faa208f15b1F2308bA229a440A7',
  marketAddress: '0x5991cD487e61c9f255730d59f47ea3F5b1871508',
}

export const ownerAddress = "0x57DB0086F8FbEa03b6550Eccb873B30f52A702FB";
export const ownerMnemonic = "reform similar raise grow diary avocado chief taxi option electric nurse caught";

const addressForTest = {
  MAIN_CONTRACT: {
    ESC: {
      sticker: "0x860edCb273D2Dd91ad76d7c0bBa88b32028F7fF4",
      market: "0xb50b892A91CD6A0eB4eeE55D989eA99aEaB0BB58",
      register: "0x7629f02684234C94BD75713e8586f8f1f4CAe53C"
    },
    ETH: {
      sticker: "0xed1978c53731997f4DAfBA47C9b07957Ef6F3961",
      market: "0x61EAE56bc110249648fB9eAe7eA4cfa185e0A498",
      register: "0xC1d40312232ec4b308E69713A98c3A2b21c8F5E0"
    },
    FSN: {
      sticker: blankAddress,
      market: "0xa18279eBDfA5747e79DBFc23fa999b4Eaf2A9780",
      register: "0x020c7303664bc88ae92cE3D380BF361E03B78B81"
    }
  },
  feedsContract: "0xb50b892A91CD6A0eB4eeE55D989eA99aEaB0BB58",
  // erc20Contract: "0xf5d461622Df01421c3b6082F962fD7711E94a579",
  v1marketContract: "0x860edCb273D2Dd91ad76d7c0bBa88b32028F7fF4",
  diaContract: "0x85946E4b6AB7C5c5C60A7b31415A52C0647E3272",
  welaContract: "0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4",
  glideContract: "0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27",
  elkContract: "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C",
  ethUsdcContract: "0xA06be0F5950781cE28D965E5EFc6996e88a8C141",
  bunnyContract: "0x75740FC7058DA148752ef8a9AdFb73966DEb42a8",
  bnbBusdContract: "0x9f1d0Ed4E041C503BD487E5dc9FC935Ab57F9a57",
  elaOnEthContract: "0x8c947E0fA67e91370587076A4108Df17840e9982",
  pasarERC20Contract: '0xa7B1a72266ad3a54Ac00071f05453feEeB5680dB',
  pasarVestingContract: '0xD883303d400427C298d53e99a3faa7Cb1E9A8727',
  pasarStakingContract: '0x048c96C7dF2d789A515c0c18c9345481c12e664E',
  pasarMiningContract: '0x8158FF69C60Eb893e32869180D980853160b8d68',
  blockchain: 'Testnet (ESC)',
}

const rpcUrlForMain = "https://api.elastos.io/eth"
const rpcUrlForTest = "https://api-testnet.elastos.io/eth"

const NetworkExplorerServer = {
  MainNet: {
    ESC: "https://esc.elastos.io",
    ETH: "https://etherscan.io",
    FSN: "https://fsnscan.com"
  },
  TestNet: {
    ESC: "https://esc-testnet.elastos.io",
    ETH: "https://ropsten.etherscan.io",
    FSN: "https://blocks.fusionnetwork.io/#!/transaction"
  }
}

const ApplicationDIDForMain = "did:elastos:iZvAak2SUHaKwBHmPFsgtVVMGtTpi4r2kY"
// const ApplicationDIDForTest = "did:elastos:ic8pRXyAT3JqEXo4PzHQHv5rsoYyEyDwpB"
export const DidResolverUrl =
  'https://api.trinity-tech.io/eid'
// process.env.REACT_APP_ENV === 'production' ? 'mainnet' : 'testnet';

export const { MAIN_CONTRACT, feedsContract, v1marketContract, diaContract, welaContract, glideContract,
  elkContract, ethUsdcContract, bunnyContract, bnbBusdContract, elaOnEthContract, pasarVestingContract, pasarStakingContract, pasarMiningContract, pasarERC20Contract, blockchain } = process.env.REACT_APP_ENV === "production" ? addressForProduction : addressForTest
export const mainDiaContract = addressForProduction.diaContract

export const rpcURL = rpcUrlForMain
export const ExplorerServer = process.env.REACT_APP_ENV === "production" ? NetworkExplorerServer.MainNet : NetworkExplorerServer.TestNet
export const ApplicationDID = ApplicationDIDForMain

export const trustedProviders = [
  "did:elastos:iqjN3CLRjd7a4jGCZe6B3isXyeLy7KKDuK" // Trinity Tech KYC
]
export const auctionOrderType = '2'

export const tokenConf = { diaDecimals: 18, diaValue: 0.01, nPPM: 1000000, PPM: 1000000 }