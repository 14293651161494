// Get the current exchange rate of Ether (ETH) to USD
// async function getEthUsdRate() {
//     let ethUsdRate = localStorage.getItem("ethUsdRate")
//     if (!localStorage.getItem("ethUsdRate")) {
//         const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
//         const data = await response.json();
//         ethUsdRate = data.ethereum.usd;
//         localStorage.setItem("ethUsdRate", ethUsdRate)
//     }
//     return ethUsdRate;
// }

// Convert a value in Wei to USD
export function convertWeiToUSD(wei, toFixed = 2) {
    const usd = (wei / 10 ** 18).toFixed(toFixed);
    return usd;
}

export async function convertUSDToWei(usd) {
    const wei = (usd * 10 ** 18).toFixed(0);
    return wei;
}