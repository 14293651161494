import React, { useEffect } from "react";
import { Avatar, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSettings from "../../hooks/useSettings";

const Languages = [
    {
        flagname: "English",
        icon: "/images/icon-flag-en.svg",
        value: "en",
    },
    {
        flagname: "Chinese",
        icon: "/images/icon-flag-cn.svg",
        value: "zh",
    },
];

export default function Language() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { language, onChangeLanguage } = useSettings();
    const { i18n } = useTranslation();
    const currentLang =
        Languages.find((_lang) => _lang.value === i18n.language) || Languages[1];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <Avatar
                    src={currentLang.icon}
                    alt={currentLang.value}
                    sx={{ width: 30, height: 30, ml:1 }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiMenu-paper": {
                        width: "200px",
                    },
                }}
            >
                {Languages.map((option, index) => (
                    <MenuItem
                        key={index}
                        sx={{ py: 2, px: 3 }}
                        onClick={() => {
                            i18n.changeLanguage(option.value);
                            onChangeLanguage(option.value)
                            setAnchorEl(null)
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Avatar
                                src={option.icon}
                                alt={option.icon}
                                sx={{ width: 20, height: 20 }}
                            />
                            <Typography> {option.flagname}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
