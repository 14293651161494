import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

// import telegramIcon from '@iconify/icons-ic/outline-telegram';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, ListItemButton, Button, Menu, MenuItem, ListItemIcon } from '@mui/material';
// components
import { useTranslation } from 'react-i18next';
import { Diamond, Info, BusinessCenter as BusinessCenterIcon, TokenOutlined as TokenOutlinedIcon } from '@mui/icons-material';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';
// components
import { HexagonButton } from '../../components/landing/HexagonButton';
import HexagonButtonWithBorder from '../../components/landing/HexagonButtonWithBorder';
// hooks
import useSignin from '../../hooks/useSignin';

// ----------------------------------------------------------------------

const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary
}));

export default function MobileNavbar() {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionId } = useSignin();

  // Dropdown state for Learn More button
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // Handle dropdown open/close
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ width: '100%', p: '0 20px', display: 'flex', justifyContent: 'space-between' }}>
        <RouterLink to="/home">
          <Box
            component="img"
            alt=""
            src="/static/landingPage/logos/colorfulLogo.svg"
            sx={{ width: 125, height: 50, cursor: 'pointer' }}
          />
        </RouterLink>
        <MIconButton onClick={handleDrawerOpen} sx={{ ml: 1 }}>
          <Icon icon={menu2Fill} />
        </MIconButton>
      </Box>
      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260, background: '#09143A' } }}
      >
        <Scrollbar sx={{ minHeight: 250 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3 }}>
            <RouterLink to="/home">
              <Box
                component="img"
                alt=""
                src="/static/landingPage/logos/colorfulLogo.svg"
                sx={{ width: 175, height: 70, cursor: 'pointer', mt: 3 }}
              />
            </RouterLink>
            {/* Learn More Button with Dropdown */}
            <Button
              onClick={handleMenuClick}
              sx={{ color: 'white', mt: 3 }}
            >
              {t("learnMore")}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { navigate('/home/learn-more'); handleMenuClose(); }}>
                <ListItemIcon>
                  <Diamond />
                </ListItemIcon>
                {t('ourDiamonds')}
              </MenuItem>
              <MenuItem onClick={() => { navigate('/home/company'); handleMenuClose(); }}>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                {t('whoWeAre')}
              </MenuItem>
              <MenuItem onClick={() => { navigate('/home/landingPageV2'); handleMenuClose(); }}>
                <ListItemIcon>
                  <BusinessCenterIcon />
                </ListItemIcon>
                {t('whatWeDo')}
              </MenuItem>
              <MenuItem onClick={() => { navigate('/home/token-details'); handleMenuClose(); }}>
                <ListItemIcon>
                  <TokenOutlinedIcon />
                </ListItemIcon>
                {t('tokenDetails')}
              </MenuItem>
            </Menu>
            {/* Other buttons below */}
            {/* <Button onClick={() => navigate('/home/company')} sx={{ mt: 3, color: 'white' }}>{t("company")}</Button> */}
            <HexagonButton onClick={() => navigate('/home/get-started')} sx={{ mt: '10px', color: 'black' }}>{t("getStartedUppercase")}</HexagonButton>
            {!sessionId &&
              <>
                <HexagonButtonWithBorder onClick={() => navigate('/home/register')} sx={{ mt: 3 }} title={t("registerNowUpperCase")} />
                <HexagonButtonWithBorder onClick={() => navigate('/home/login')} sx={{ mt: 3 }} title={t("signin")} textSx={{ fontSize: '1.1rem' }} />
              </>}
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
}