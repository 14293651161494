/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

const envTypes = {
    prod: "prod",
    ppe: "ppe",
    test: "test"
}
export const getCurrentEnv = () => {
    const params = new URLSearchParams(window.location.search);
    const env = params.get('env');
    const storedEnv = localStorage.getItem("env");
    return env ?? storedEnv ?? process.env.REACT_APP_ENV;
}

export const CHAT_BOT_BACKEND_URL = getCurrentEnv() !== envTypes.test ? "https://shoppingbot.azurewebsites.net" : "https://localhost:7175"
export const PAYPAL_ENV = getCurrentEnv() === envTypes.prod ? "production" : "sandbox"
export const PAYPAL_URL = getCurrentEnv() === envTypes.prod ? "https://api-m.paypal.com" : "https://api-m.sandbox.paypal.com"
export const PAYPAL_CLIENT_ID = getCurrentEnv() === envTypes.prod ? "AWI2vgiNzc1Uq9BPzqGwuKOgZx7nH6R8VyAi5UlAA4moMNHs0lX_oxJEtTuIU8HO4C_oSJkZ92DWd_bN" : "ASMTiwZBppyxaA3W40XKvMTemJZ-3Z5KZn2By5LPUTH4Zb94Q9fjDNI9kb3hYUk2Pp3QQerFvzIYCI1Q"
export const FRONTEND_URL_PRODUCTION = getCurrentEnv() === envTypes.prod ? "https://opendiamond.io" : getCurrentEnv() === envTypes.ppe ? "https://ppe.opendiamond.io" : "http://localhost:3000"