import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import { Outlet } from 'react-router-dom';
// material
import { Box } from '@mui/material';
// components
import useSettings from '../../hooks/useSettings';
import useSingin from '../../hooks/useSignin';
import MainNavbar from './MainNavbar';

// ----------------------------------------------------------------------

function ScrollTop(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: { xs: 56, sm: 36 }, right: 22, zIndex: 2 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired
};

const BodyStyle = styled('div')(({ theme, footerhidden }) => (
  footerhidden ? {} :
    {
      [theme.breakpoints.up('md')]: {
        paddingBottom: 200
      }
    }
));

export default function LandingLayout() {
  const { themeMode } = useSettings();
  const { openTopAlert } = useSingin()
  return (
    <>
      <MainNavbar />
      <BodyStyle style={{ paddingTop: openTopAlert ? 50 : 0, transition: 'padding-top 0.3s' }}>
        <Outlet />
      </BodyStyle>
      <ScrollTop>
        <Fab variant="contained" size="small" aria-label="scroll back to top" sx={{ mb: 8, mr: 0.5 }} color={themeMode === 'light' ? 'primary' : 'default'}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}
