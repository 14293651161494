import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types';
import { fetchFrom } from '../utils/common';
import { convertWeiToUSD } from '../components/signin-dlg/currencyHandler';

// ----------------------------------------------------------------------

const initialState = {
  balance: 0,
  setBalance: () => { },
};

const SigninContext = createContext(initialState);

SigninProvider.propTypes = {
  children: PropTypes.node
};

function SigninProvider({ children }) {
  const [balance, setBalance] = useState(null);
  const [user, setUser] = useState(undefined);
  const [store, setStore] = useState(undefined);
  const [sessionId, setSessionId] = useState(null);
  const [userFetchLoading, setUserFetchLoading] = useState(false);

  React.useEffect(() => {
    const sessId = localStorage.getItem("sessionId");
    if (sessId) {
      setSessionId(sessId)
    } else {
      setSessionId(null)
    }

  }, [])
  React.useEffect(() => {
    getUser();
  }, [sessionId])

  const getUser = () => {
    if (sessionId) {
      setUserFetchLoading(true)
      fetchFrom(`api/v2/user/getUser?sessionId=${sessionId}`).then((userResJson) => {
        userResJson.json().then(async (userRes) => {
          if (userRes.body.error) {
            setSessionId(null)
          } else {
            const { user } = userRes.body;
            if (user) {
              user.wallet.balance = convertWeiToUSD(user.wallet.balance)
              setUser(user);
            }
          }
          setUserFetchLoading(false)
        });
      });
    } else {
      setUser(null);
    }
  }

  return (
    <SigninContext.Provider
      value={{
        balance,
        setBalance,
        user,
        setUser,
        sessionId,
        setSessionId,
        getUser,
        store,
        setStore,
        userFetchLoading,
      }}
    >
      {children}
    </SigninContext.Provider>
  );
}

export { SigninProvider, SigninContext };
