export default async function setGemListToLocalStorage() {
    const url = `${process.env.REACT_APP_VPLUS_API_URL}app/desGemList`;
    const body = { userId: 20251 }
    const requestOptions = {
        method: 'POST',
        async: false,
        body: JSON.stringify(body)
    };
    try {
        if (!localStorage.getItem("gemList")) {
            const response = await fetch(url, requestOptions)
            const res = await response.json()
            localStorage.setItem("gemList", JSON.stringify(res.list))
        }
    } catch (e) { console.log(e) }
}