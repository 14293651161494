import { Button, styled } from "@mui/material";

export const HexagonButton = styled(({ onClick, ...props }) => (
  <Button onClick={onClick} {...props} />
))({
  position: 'relative',
  display: 'inline-block',
  padding: '16.5px 35px',
  border: 'none',
  background: 'linear-gradient(to right, #7C5BF9, #C669F5 21%, #94EEF6 97%);',
  color: '#fff',
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textDecoration: 'none',
  clipPath: 'polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%)',
});
